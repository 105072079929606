import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./label.module.scss";
import DiscountAsPercentage from "./discount-as-percentage";

const DiscountedPriceWrapper = ({ children, discount, text }) => {
  return (
    <div className={classnames(styles.label__prices, styles.label__prices__discounted)}>
      <span>{text}</span>
      <span className={styles.label__discountedPriceWrapper}>
        <DiscountAsPercentage discount={discount} />
        <span className={styles.label__discountedPriceWrapper__strikethrough}>{children}</span>
      </span>
    </div>
  );
};

DiscountedPriceWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  discount: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default DiscountedPriceWrapper;
