import PropTypes from "prop-types";
import styles from "./label.module.scss";

const DiscountAsPercentage = ({ discount }) => {
  const isValidDiscount = typeof discount === "number" && !isNaN(discount) && discount >= 0 && discount <= 1;
  const formattedDiscount = isValidDiscount ? `-${(discount * 100).toFixed(0)}%` : "";

  return <span className={styles.label__discountPercentage}>{formattedDiscount}</span>;
};

DiscountAsPercentage.propTypes = {
  discount: PropTypes.number.isRequired,
};

export default DiscountAsPercentage;
