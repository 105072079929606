import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import classnames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";

import { browserPropType, reviewsCoverPhotosPropType, membershipStatePropType } from "components/propTypes";
import UserProductRatingsContainer from "components/source/user_product_ratings/user-product-ratings-container";
import Routes from "routes";
import { isAdmin } from "components/source/hoc/with-user-data";
import AvailableSizes from "components/source/new_taxonomy/available-sizes";
import ProductAdminLinks from "components/source/new_taxonomy/product-admin-links";
import ActionLogger from "action-logger";
import ProductImageRotation from "helpers/product-image-rotation";
import ProductAvailability from "helpers/product-availability";
import { hasTouchEventSupport } from "helpers/device-helpers";
import AtomCustomerReviewsLink from "components/source/atoms/atom-customer-reviews-link";
import AtomProductCardPrice from "components/source/atoms/atom-product-card-price";
import UrgencyMessage from "components/source/atoms/atom-urgency-message";
import MaternityHelper from "helpers/maternity-helper";
import { analytics, pageTypes, RENTAL_INTENT } from "rtr-constants";
import ProductImage from "../shared/product-image";
import { getProductImageAltText, ProductImageSize } from "../../../helpers/product-image-helpers";
import GTMHelper from "helpers/gtm";
import Converge from "helpers/converge";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";
import MembershipHelpers from "helpers/membership-helpers";

const noop = () => {}; // NOSONAR

class ProductComponent extends React.Component {
  static propTypes = {
    additionalClassName: PropTypes.string,
    browser: browserPropType,
    carousel: PropTypes.any, // TODO: what is this PropType?
    carouselIndex: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.object),
    currentIntent: PropTypes.oneOf(Object.values(RENTAL_INTENT)),
    customUrgencyMessage: PropTypes.string,
    customHeartLoggingOptions: PropTypes.object,
    deadEndAPIAndCarouselData: PropTypes.object,
    deadEndCarousel: PropTypes.bool,
    deadEndSearchData: PropTypes.object,
    extraPixelData: PropTypes.object,
    filters: PropTypes.object,
    hideRatingsActions: PropTypes.bool,
    hierarchy: PropTypes.arrayOf(PropTypes.object),
    isBuyNow: PropTypes.bool,
    isClearanceGrid: PropTypes.bool,
    isDiscoCarouselFFEnabled: PropTypes.bool,
    isMembershipHomepage: PropTypes.bool,
    isMembershipHomepageProductDrawerSuppressed: PropTypes.bool,
    isPostShipmentModal: PropTypes.bool,
    isProductDrawerEnabled: PropTypes.bool,
    isUnlimitedGrid: PropTypes.bool,
    lens: PropTypes.string,
    location: PropTypes.oneOf([
      analytics.ACTION_LOCATIONS.CAROUSEL,
      analytics.ACTION_LOCATIONS.DRAWER,
      analytics.ACTION_LOCATIONS.GRID,
      analytics.ACTION_LOCATIONS.PDP,
    ]),
    logNavigateDirectlyToPDPNoDrawer: PropTypes.func,
    logQuickviewCarouselItemClick: PropTypes.func,
    membershipState: membershipStatePropType,
    noModelImageFirst: PropTypes.bool,
    openGridProductDrawer: PropTypes.func,
    openProductDrawer: PropTypes.func,
    personalizedCarouselItemIndex: PropTypes.number,
    price: PropTypes.shape({
      merchandiseCategory: PropTypes.string,
    }),
    priceDisplayOverrides: PropTypes.shape({
      showClassicPricing: PropTypes.bool,
      showMembershipPricing: PropTypes.bool,
      showOnlyRetailPrice: PropTypes.bool,
    }),
    product: PropTypes.object.isRequired,
    productIndex: PropTypes.number,
    reviewsCoverPhotos: reviewsCoverPhotosPropType,
    scrollIntoViewLoggingOptions: PropTypes.object,
    shortlists: PropTypes.arrayOf(PropTypes.object),
    shouldIncludeProductPrice: PropTypes.bool,
    hideProductInfo: PropTypes.bool,
    showUnavailable: PropTypes.bool,
    userData: PropTypes.object,
    loading: PropTypes.string,
    isClearance: PropTypes.bool,
  };

  static defaultProps = {
    hideRatingsActions: false,
    isClearanceGrid: false,
    isMembershipHomepageProductDrawerSuppressed: false,
    logNavigateDirectlyToPDPNoDrawer: noop,
    openProductDrawer: noop,
    showUnavailable: false,
    loading: "eager",
  };

  productImageRef = React.createRef();
  elementRef = React.createRef();

  componentDidMount() {
    const { scrollIntoViewLoggingOptions } = this.props;
    if (scrollIntoViewLoggingOptions) {
      this.scrollPositionLogger = createScrollIntoViewPixelLogger(
        this.elementRef,
        "visible_products",
        scrollIntoViewLoggingOptions
      );
    }
  }

  componentWillUnmount() {
    if (this.scrollPositionLogger) {
      this.scrollPositionLogger.disconnect();
    }
  }

  isAvailable = () => {
    return ProductAvailability.isAvailable(this.props.product.skus);
  };

  isAccessory = () => {
    let category;

    if (this.props.hierarchy) {
      return _.chain(this.props.hierarchy).pluck("id").include("accessory").value();
    }

    category = _.findWhere(this.props.categories, { id: this.props.product?.category?.id });

    while (category && _.has(category, "parent") && category.parent) {
      category = _.findWhere(this.props.categories, { id: category.parent.id });

      if (category?.id === "accessory") {
        return true;
      }
    }
    return false;
  };

  loadImages = () => {
    if (!this.state.loadAllImages) {
      this.setState({
        loadAllImages: true,
      });
    }
  };

  renderImage = (product, imageGroup, key) => {
    return (
      <div className={"grid-product-card-image cycle-image cycle-image-" + key} key={product.id + "-" + key}>
        <ProductImage
          altText={getProductImageAltText(product?.displayName, product?.designer?.displayName)}
          imageGroup={imageGroup}
          imageSize={this.isMobileViewport() ? ProductImageSize.x270 : ProductImageSize.x800}
          loading={this.props.loading}
        />
      </div>
    );
  };

  getImageGroups = () => {
    const { product: { images } = {}, userData, noModelImageFirst } = this.props;
    const filteredSizes = this.filteredSizes();

    if (!images) {
      return [];
    }

    const showMaternityBumpImage = MaternityHelper.showMaternityBumpImage(userData);
    const imageRotation = ProductImageRotation(
      filteredSizes,
      this.props.product,
      showMaternityBumpImage,
      noModelImageFirst
    );

    return _.compact(
      _.map(imageRotation, function (key) {
        return images[key];
      })
    );
  };

  filteredSizes = () => {
    return this.props.filters?.canonicalSizes || [];
  };

  renderUrgencyTag = () => {
    if (this.props.customUrgencyMessage) {
      return <UrgencyMessage message={this.props.customUrgencyMessage} additionalClassname="product-card" />;
    }
  };

  renderUnavailableTag() {
    if (this.isAvailable()) return;

    // use the same className as UrgencyMessage (with unavailable added)
    return <UrgencyMessage message="Unavailable in all sizes" additionalClassname="product-card unavailable" />;
  }

  renderImages = () => {
    const { loadAllImages } = this.state;
    let imageGroups = this.getImageGroups();

    if (!loadAllImages || this.isMobileViewport()) {
      imageGroups = imageGroups.slice(0, 1);
    }

    const imgContainer = () => (
      <div
        className={"grid-product-card-image-wrapper parent-" + imageGroups.length + "-slides"}
        onMouseOver={hasTouchEventSupport() ? null : this.loadImages}>
        {_.map(
          imageGroups,
          function (imageGroup, key) {
            return this.renderImage(this.props.product, imageGroup, key);
          },
          this
        )}
      </div>
    );

    return this.props.product.id ? (
      <a
        data-test-id={`detail-link-${this.props.product.id}`}
        className="grid-product-card-detail-link"
        href={this.renderProductLink()}
        onClick={this.handleClickOnProduct}
        ref={this.productImageRef}>
        {imgContainer()}
      </a>
    ) : (
      imgContainer()
    );
  };

  adminAvailableSizesMarkup = () => {
    const { isClearanceGrid, isUnlimitedGrid, product, userData, isBuyNow } = this.props;
    const { isAccessory } = this.state;

    if (!isAdmin(userData) || isAccessory) {
      return null;
    }

    return (
      <AvailableSizes
        filteredSizes={this.filteredSizes()}
        skus={product.skus}
        isClearanceGrid={isClearanceGrid}
        isUnlimitedGrid={isUnlimitedGrid || isBuyNow}
      />
    );
  };

  adminMarkup = () => {
    if (!isAdmin(this.props.userData) || !this.props.product.id) {
      return null;
    }

    return <ProductAdminLinks productId={this.props.product.id} />;
  };

  logClick = () => {
    const { extraPixelData = {} } = this.props;

    if (!this.props.deadEndCarousel) {
      const options = {
        pageType: pageTypes.PDP,
        object_type: "grid",
        action: "infer",
        styleName: this.props.product.id,
        // rentBegin: "09-15-2015", // not accessible to this component yet
        // numStyles: 201, // not accessible to storefront yet
        // page: 1, // not accessible to this component yet
        // index: this.props.index // this should be relative to the result set: index + (page * pageLength)
      };
      if (extraPixelData.nthStyleRequired) {
        options.nth_style = this.props.productIndex;
        delete extraPixelData["nthStyleRequired"];
      }
      const dataToLog = {
        ...options,
        ...extraPixelData,
      };

      ActionLogger.inferAction(dataToLog);
    }
  };

  getCoverPhotoId() {
    const { product, reviewsCoverPhotos } = this.props;

    const firstReviewCoverPhoto = reviewsCoverPhotos?.[0]?.id;

    if (firstReviewCoverPhoto) return firstReviewCoverPhoto;

    return product?.reviews?.find(r => r.coverPhotoId)?.coverPhotoId;
  }

  customerReviewsLink = () => {
    const coverPhotoId = this.getCoverPhotoId();

    if (!coverPhotoId) return;

    return (
      <div className="grid-product-card__reviews">
        <AtomCustomerReviewsLink />
      </div>
    );
  };

  renderProductLink = () => {
    const { isBuyNow, lens } = this.props;
    let baseRoute = Routes.PDP.RouteById(this.props.product.id);

    const params = [
      !isBuyNow && lens ? `lens=${encodeURIComponent(lens)}` : "",
      isBuyNow ? `buynow=${encodeURIComponent(isBuyNow)}` : "",
    ]
      .filter(Boolean)
      .join("&");

    // The pdp link /pdp/items/:style_name results in a redirect (ruby layer)
    // to the last entry in the urlHistory of a product (a.k.a the legacy_product_url).
    // If the urlHistory is available we can skip this redirect by using the last url
    // in the list.
    const urlHistory = this.props.product.urlHistory;

    if (Array.isArray(urlHistory) && urlHistory.length > 0) {
      const latestUrl = urlHistory[urlHistory.length - 1];
      const parsedUrl = new URL(latestUrl);
      baseRoute = latestUrl.replace(parsedUrl.origin, "");
    }

    return `${baseRoute}${params ? `?${params}` : ""}`;
  };

  productNameAndDesignerMarkup() {
    const { product, membershipState } = this.props;
    const { designer = {} } = product || {};

    const isActiveSubscriber = MembershipHelpers.isActiveMembership(membershipState);

    if (isActiveSubscriber) {
      return (
        <h2 className="universal-small--semibold grid-product-card-designer">
          {designer.displayName}
          <span className="universal-small grid-product-card-display-name">{product.displayName}</span>
        </h2>
      );
    } else {
      return (
        <h2 className="universal-small--semibold grid-product-card-designer">
          {designer.displayName}
          {!this.isMembershipOrUnknownIntentExperience() && (
            <span className="universal-small grid-product-card-display-name">{product.displayName}</span>
          )}
        </h2>
      );
    }
  }

  productInfoMarkup = () => {
    const {
      isBuyNow,
      hideProductInfo = false,
      priceDisplayOverrides = {},
      product,
      shouldIncludeProductPrice,
    } = this.props;

    const price = shouldIncludeProductPrice ? product.price : null;

    if (hideProductInfo) {
      return null;
    }

    return (
      <a className="grid-product-card-info" href={this.renderProductLink()} onClick={this.handleClickOnProduct}>
        {this.productNameAndDesignerMarkup()}
        <AtomProductCardPrice
          designerId={product?.designer?.id}
          isBuyNow={isBuyNow}
          price={price}
          priceDisplayOverrides={{
            ...priceDisplayOverrides,
            showOnlyRetailPrice:
              priceDisplayOverrides.showOnlyRetailPrice || this.isMembershipOrUnknownIntentExperience(),
          }}
          purchasePrice={product.purchasePrice}
          retailPrice={product.retailPrice}
          clearancePrice={product.clearancePrice}
          isClearanceProduct={this.props.isClearance || false}
          exclusiveDesign={product.exclusiveDesign}
        />
      </a>
    );
  };

  getDrawerProductInfo() {
    const {
      productIndex,
      product,
      product: { id: productId },
    } = this.props;
    const params = { productIndex, productId, product };
    const parentElement = this.productImageRef?.current?.closest(".grid-product-card");

    if (parentElement) {
      params.parentElement = parentElement;
    }

    return params;
  }

  isDrawerEnabled() {
    const { isProductDrawerEnabled, isMembershipHomepage, isMembershipHomepageProductDrawerSuppressed } = this.props;

    // is enabled, enabled!
    if (isProductDrawerEnabled) return true;

    // not enabled if explicitly suppressed
    if (isMembershipHomepageProductDrawerSuppressed) return false;

    // homepage and not suppressed
    return isMembershipHomepage;
  }

  /**
   * If the user has Membership or No intent, we hide various product fields (rental price, style name) and actions (ratings).
   * Ineligible users (experiment control or active subs) will have currentIntent as null.
   */
  isMembershipOrUnknownIntentExperience() {
    const { currentIntent } = this.props;

    return [RENTAL_INTENT.MEMBERSHIP, RENTAL_INTENT.NO_INTENT].includes(currentIntent);
  }

  openDrawer = () => {
    const { isProductDrawerEnabled, isMembershipHomepage, openProductDrawer } = this.props;
    if (isProductDrawerEnabled) {
      openProductDrawer(this.getDrawerProductInfo());
    } else if (isMembershipHomepage) {
      this.handleMembershipHomepageOpenDrawer();
    }
  };

  handleMembershipHomepageOpenDrawer() {
    const {
      carousel,
      personalizedCarouselItemIndex: carouselItemIndex,
      carouselIndex,
      openProductDrawer,
      logQuickviewCarouselItemClick,
    } = this.props;
    const productWithCarousel = {
      ...this.getDrawerProductInfo(),
      carousel,
      carouselItemIndex,
      carouselIndex,
      object_type: analytics.ACTION_LOCATIONS.CONTROL_CENTER,
    };
    if (this.props.isDiscoCarouselFFEnabled) {
      this.props.openGridProductDrawer(this.props.product);
    } else {
      openProductDrawer(productWithCarousel);
    }
    logQuickviewCarouselItemClick(productWithCarousel);
  }

  /**
   * NW [EXPLANATION] 3/23/20: user is navigating directly to PDP
   * from a carousel product (they are not going through a drawer).
   * we need to pass context about the product's position in the
   * carousel to this log action.
   *
   * NS [EXPLANATION] 06/06/2022: the log action could be undefined if this
   * is being rendered in PersonalizedCarousels.renderProducts().
   */
  logMembershipHomepageNavigateToPDP() {
    const {
      product: { id: style_name },
      carousel: { id: carousel_id, requestId: request_id } = {},
      personalizedCarouselItemIndex: nth_style,
      carouselIndex: nth_carousel,
      logNavigateDirectlyToPDPNoDrawer,
    } = this.props;
    const carouselContext = { style_name, carousel_id, request_id, nth_style, nth_carousel };

    if (logNavigateDirectlyToPDPNoDrawer) {
      logNavigateDirectlyToPDPNoDrawer(carouselContext);
    }
  }

  handleClickOnProduct = e => {
    const { isMembershipHomepage, logNavigateDirectlyToPDPNoDrawer, userData } = this.props;

    if (this.isDrawerEnabled()) {
      e.preventDefault();
      this.openDrawer();
    } else if (isMembershipHomepage) {
      this.logMembershipHomepageNavigateToPDP();
    } else {
      // NW [EXPLANATION] 3/23/20: user is navigating directly to PDP
      // from a grid. unlike the homepage case above, there is no context
      // about the product's position in the carousel to be passed to the log action.
      logNavigateDirectlyToPDPNoDrawer(this.getDrawerProductInfo());
      this.logClick();
    }
    GTMHelper.viewItemEvent(this.props.product, userData?.userProfile?.id, userData?.userProfile?.email);
    Converge.trackSelectedProduct(this.props.product);
  };

  state = {
    loadAllImages: false,
    isAccessory: this.isAccessory(),
  };

  isMobileViewport() {
    const { browser: { isMobileViewport: mobileViewportSuggestion = true } = {} } = this.props;

    return mobileViewportSuggestion;
  }

  render() {
    // The swaps grid hides both hearts and similar styles
    const {
      additionalClassName,
      carousel,
      customHeartLoggingOptions,
      deadEndCarousel,
      deadEndAPIAndCarouselData,
      deadEndSearchData,
      hideRatingsActions,
      isPostShipmentModal,
      product,
      productIndex,
      showUnavailable,
      shortlists,
    } = this.props;
    const { id: styleName } = product;

    if (!this.isAvailable() && !showUnavailable) {
      // We may want to provide some kind of different visual treatment for
      // unavailable products again in the future. For now, let's skip them.
      // On the swaps grid page we display unavailable styles with a banner
      return null;
    }

    // MH Explanation 10-8-19: override location for the post shipment modal
    const location = isPostShipmentModal ? analytics.ACTION_LOCATIONS.POST_SHIP_MODAL : this.props.location;
    const classNames = classnames("grid-product-card", { [additionalClassName]: additionalClassName });

    // we should revisit accessibility on this grid further, and also after the experiment is over
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={classNames} ref={this.elementRef} data-style-name={styleName}>
          <UserProductRatingsContainer
            customHeartLoggingOptions={customHeartLoggingOptions}
            deadEndCarousel={deadEndCarousel}
            deadEndSearchData={deadEndSearchData}
            deadEndAPIAndCarouselData={deadEndAPIAndCarouselData}
            location={location}
            product={product}
            productIndex={productIndex}
            shortlists={shortlists}
            hidden={hideRatingsActions}
            isInCarousel={!_.isEmpty(carousel)}>
            {this.renderImages()}
            {this.renderUrgencyTag() || this.renderUnavailableTag()}
          </UserProductRatingsContainer>
          <div className="grid-product-card-meta">
            {this.productInfoMarkup()}
            <div className="grid-product-card-admin">
              {this.adminAvailableSizesMarkup()}
              {this.customerReviewsLink()}
              {this.adminMarkup()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  browser: state.browser,
});

export default compose(connect(mapStateToProps))(ProductComponent);
