import classnames from "classnames";
import Price from "./price";
import PropTypes from "prop-types";
import styles from "./label.module.scss";
import DiscountedPriceWrapper from "./discounted-price-wrapper";
import PriceWrapper from "./price-wrapper";

const ClearancePricing = ({ amount, comparableAmount, comparableText, discount, currencyCode, showDecimal = true }) => {
  return (
    <>
      <PriceWrapper text="Sale" className={styles.label__prices__urgent}>
        <Price
          className={classnames(styles.label__price)}
          amount={amount}
          currencyCode={currencyCode}
          currencyCodeClassName="hidden @[275px]/label:inline"
          showDecimal={showDecimal}
        />
      </PriceWrapper>

      {!!comparableAmount && discount > 0 && (
        <DiscountedPriceWrapper text={comparableText} discount={discount}>
          <Price
            className={classnames(styles.label__price)}
            amount={comparableAmount}
            currencyCode={currencyCode}
            currencyCodeClassName="hidden @[275px]/label:inline"
            showDecimal={showDecimal}
          />
        </DiscountedPriceWrapper>
      )}

      {!!comparableAmount && (!discount || discount === 0) && (
        <PriceWrapper text={comparableText}>
          <Price
            className={classnames(styles.label__price)}
            amount={comparableAmount}
            currencyCode={currencyCode}
            currencyCodeClassName="hidden @[275px]/label:inline"
            showDecimal={showDecimal}
          />
        </PriceWrapper>
      )}
    </>
  );
};

ClearancePricing.propTypes = {
  amount: PropTypes.number.isRequired,
  comparableAmount: PropTypes.number,
  comparableText: PropTypes.string,
  currencyCode: PropTypes.string.isRequired,
  discount: PropTypes.number,
  showDecimal: PropTypes.bool,
};

export default ClearancePricing;
