import PropTypes from "prop-types";

const PriceWrapper = ({ children, text, className }) => {
  return (
    <div className={className}>
      <span>{text}</span>
      {children}
    </div>
  );
};

PriceWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PriceWrapper;
